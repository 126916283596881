<!-- 
 * @Author: Corn 
 * @Date: 2022-11-22 14:14:23 
 * @Last Modified by:   Corn 
 * @Description: 设备管理
 * @Last Modified time: 2022-11-22 14:14:23 
  -->
<template>
    <div class="contents">
        <Cards cardName="" cardWidth="100%" class="mb20">
            <template v-slot:right>
                <div class="bg roll">
                    <span class="span ml20">设备型号：</span>
                    <el-select v-model="queryEqu.product_key" placeholder="请选择" @change="changeSelect(true)">
                        <el-option v-for="item in productArr" :key="item.ProductKey" :label="item.ProductName"
                            :value="item.ProductKey"></el-option>
                    </el-select>
                    <span class="span ml20">设备状态：</span>
                    <el-select v-model="queryEqu.status" placeholder="请选择" clearable @change="deviceTable(false)">
                        <el-option label="在线" value="ONLINE" />
                        <el-option label="离线" value="OFFLINE" />
                        <el-option label="未激活" value="UNACTIVE" />
                        <el-option label="禁用" value="DISABLE" />
                    </el-select>
                    <span class="span ml20">sn号：</span>
                    <el-input v-model="queryEqu.device_name" placeholder="请输入设备sn号" clearable
                        @change="deviceTable(false)" style="width: 20%"></el-input>
                </div>
            </template>
            <el-table :data="deviceData.list" style="width: 100%" max-height="590" height="590">
                <el-table-column fixed prop="DeviceName" label="SN号码" />
                <el-table-column prop="Status" label="设备状态">
                    <!-- <template slot-scope="scope">
                        <span>{{
                            scope.row.Status == 'ONLINE' ? '在线' : scope.row.Status == 'OFFLINE' ? '离线' :
                                scope.row.Status == 'UNACTIVE' ? '未激活' : '禁用'
                        }}</span>
                    </template> -->
                </el-table-column>
                <el-table-column prop="GmtCreate" label="创建时间" />
                <el-table-column prop="GmtModified" label="最后更新时间" />
                <el-table-column fixed="right" label="操作" width="100">
                    <template slot-scope="scope">
                        <el-button @click="lookClick(scope.row)" type="text" size="small">查看</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <Pagination :pageSize="10" :pageSizes="[50]" :total="deviceData.count" @sizeChange="(e) => sizeChange(e, 3)"
                @currentChange="(e) => currentChange(e, 3)" />
        </Cards>
        <Detail :show.sync="show" :obj="objD" class="detail" />
    </div>
</template>

<script>
import { deviceData, deviceList, deviceLists, productList } from '../../api/equipment'
import Cards from '../../components/Cards/Cards.vue'
import Pagination from '../../components/Pagination/Pagination.vue'
import { delEmpty, GMTToStr } from '../../utils/methods'
import Detail from './components/detail.vue'
export default {
    components: {
        Cards,
        Pagination,
        Detail,
    },
    data() {
        return {
            //设备型号
            productArr: [],
            product_key: '',
            //设备数据表格
            deviceData: { list: [], count: 0 },
            queryEqu: { step: 50, page: 1 },
            show: false,
            objD: {},
            portType: true,
        }
    },
    mounted() {
        this.getDatas(true)
    },
    watch: {},
    methods: {
        //
        async getDatas() {
            //产品列表-设备型号
            let obj = await productList({ step: 100 })
            if (obj.status) {
                this.queryEqu.product_key = obj.data.list[0].ProductKey
                this.productArr = obj.data.list
                this.deviceTable(true)
            } else {
                this.productArr = []
            }
        },
        changeSelect() {
            this.queryEqu.next_token = ''
            this.deviceTable(true)
        },
        //设备列表
        async deviceTable(type) {
            this.portType = type
            if (type) {
                this.queryEqu.status = ''
                this.queryEqu.device_name = ''
            } else {
                this.queryEqu.next_token = ''
            }
            let res = this.portType ? await deviceList(delEmpty(this.queryEqu)) : await deviceLists(this.queryEqu)
            if (res.status) {
                if (this.portType) {
                    this.queryEqu.next_token = res.data.next_token
                }
                this.deviceData.count = res.data.count
                this.deviceData.list = res.data.list.map((item) => {
                    if (this.portType) {
                        item.Status = item.DeviceStatus == 'ONLINE' ? '在线' : item.DeviceStatus == 'OFFLINE' ? '离线' : item.DeviceStatus == 'UNACTIVE' ? '未激活' : '禁用'
                    } else {
                        item.Status = item.Status == 'ONLINE' ? '在线' : item.Status == 'OFFLINE' ? '离线' : item.Status == 'UNACTIVE' ? '未激活' : '禁用'
                    }
                    item.GmtCreate = GMTToStr(item.GmtCreate)
                    item.GmtModified = GMTToStr(item.GmtModified)
                    return item
                })
            } else {
                this.$Message.error('失败')
                this.deviceData.count = 0
                this.deviceData.list = []
            }
        },
        lookClick(row) {
            this.show = true
            this.objD = {
                product_key: row.ProductKey,
                device_name: row.DeviceName,
                iot_id: row.IotId,
            }
        },
        //分页
        sizeChange(e, type) {
            this.queryEqu.step = e
            this.deviceTable(this.portType)
        },
        currentChange(e, type) {
            this.queryEqu.page = e
            this.deviceTable(this.portType)
        },
    },
}
</script>

<style lang="scss" scoped>
.contents {
    position: relative;
    height: 88vh;

    .detail {
        position: absolute;
        width: 100%;
        height: 100%;
        background: #fff;
        border-radius: 10px;
        z-index: 10;
        top: 0;
    }
}

.input {
    display: inline-flex;
    width: 20%;
}
</style>
